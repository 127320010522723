import React from "react";
import './CV.scss';

const CV = () => {
    return (
        <div className="cv">
            <section>
                <h2>Education</h2>
                <p><strong>2017-2018:</strong> MSc World Heritage Management and Conservation, UCD, Dublin, IE.</p>
                <p><strong>2012-2016:</strong> BA Visual Art Practice, IADT, Dublin, IE.</p>
                <p><strong>2011-2012:</strong> Art and Design, Portfolio Preparation at Liberties College Dublin, IE.</p>
            </section>

            <section>
                <h2>Solo Exhibitions</h2>
                <p><strong>2023:</strong> Upland Folk, Claremorris Gallery, Mayo, IE.</p>
                <p><strong>2022:</strong> Upland Folk, Gimnasio de Arte y Cultura, Mexico City, MX.</p>
            </section>

            <section>
                <h2>Group Exhibitions</h2>
                <p><strong>2019 Winter Show:</strong> SO Fine Art Gallery, Dublin, IE.</p>
                <p><strong>2019 VUE Art Fair:</strong> SO Fine Art booth, RHA, Dublin, IE.</p>
                <p><strong>2019 Summer Show:</strong> SO Fine Art Gallery, Dublin, IE.</p>
                <p><strong>2019 Dunamaise Art Centre Group Show:</strong> curated by Robert Armstrong, Portlaoise, IE.</p>
                <p><strong>2019 Onwards:</strong> MART, Dublin, IE.</p>
                <p><strong>2018 Stream at Sailors Home:</strong> The Sailors Home. Limerick, IE.</p>
                <p><strong>2018 Stream:</strong> The Complex. Dublin, IE.</p>
                <p><strong>2017 Stream:</strong> The Complex. Dublin, IE.</p>
                <p><strong>2016 Conference:</strong> IADT graduation show, Institute of Art Design and Technology Dun Laoghaire. Dublin, IE.</p>
                <p><strong>2016 Juvenoia:</strong> Pallas Projects and Studios. Dublin, IE.</p>
                <p><strong>2015 Islands:</strong> MART. Dublin, IE.</p>
                <p><strong>2014 Separate:</strong> MART. Dublin, IE.</p>
                <p><strong>2013 IMMAgination:</strong> The Drawing Project. Dublin, IE.</p>
            </section>

            <section>
                <h2>Awards</h2>
                <p><strong>2021 </strong>Arts Council Agility Award</p>
                <p><strong>2021 </strong>Fingal Artist Support Scheme Bursary Award</p>
                <p><strong>2021 </strong>Leitrim Individual Artist Bursary</p>
                <p><strong>2016 </strong>Shortlisted for Talbot Studios Most Promising Graduate Award</p>
            </section>

            <section>
                <h2>Residencies</h2>
                <p><strong>2016-2017:</strong> RHA Peer Residency Programme</p>
            </section>

            <section>
                <h2>Collections</h2>
                <p>OPW Collection</p>
            </section>

            <section>
                <h2>Workshops and Projects</h2>
                <p><strong>2022:</strong> Cultural Exchange Workshop  and Artist talk at Gimnasio de Arte y Cultura, Mexico City</p>
                <p><strong>2017:</strong> Baldoyle Family Research Centre - Mural painting project with ‘Guinea Pigs’ teenage girl outreach group.</p>
            </section>    

        </div>
    )

};

export default CV;
export const routes = [
    {
      title: "Home",
      href: "/", // Assuming you want to link to the root
    },
    {
      title: "About",
      href: "/about", // Link to the About page
    },
    {
      title: "CV",
      href: "/cv", // Link to the CV page
    },
    {
      title: "Contact",
      href: "/contact", // Link to the Contact page
    },
  ];
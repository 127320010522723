const paintingsData = {
    "2022": [
      {
        title: "Remnants of the Wild Hunt",
        description: "Oil on board in artist made frame,",
        dimensions: "61cm x 41cm,",
        date: "2022",
        sizes: {
          small: "/images/2022/WildHunt-small.webp",
          medium: "/images/2022/WildHunt-medium.webp",
          large: "/images/2022/WildHunt-large.webp",
        },
      },
      {
        title: "Dawn",
        description: "Oil on board in artist made frame,",
        dimensions: "55cm x 53.5cm,",
        date: "2022",
        sizes: {
          small: "/images/2022/Dawn-small.webp",
          medium: "/images/2022/Dawn-medium.webp",
          large: "/images/2022/Dawn-large.webp",
        },
      },
      {
        title: "Escape from the Cave",
        description: "Oil on board in artist made frame,",
        dimensions: "71.5cm x 40.5cm,",
        date: "2022",
        sizes: {
          small: "/images/2022/EscapeFromTheCave-small.webp",
          medium: "/images/2022/EscapeFromTheCave-medium.webp",
          large: "/images/2022/EscapeFromTheCave-large.webp",
        },
      },
      {
        title: "Morning Sun",
        description: "Oil on board in artist made frame,",
        dimensions: "59.5cm x 52.5cm,",
        date: "2022",
        sizes: {
          small: "/images/2022/SummerDay-small.webp",
          medium: "/images/2022/SummerDay-medium.webp",
          large: "/images/2022/SummerDay-large.webp",
        },
      },
      {
        title: "Cycle of the Goddess",
        description: "Oil on board in artist made frame,",
        dimensions: "59cm x 50cm,",
        date: "2022",
        sizes: {
          small: "/images/2022/Goddess-small.webp",
          medium: "/images/2022/Goddess-medium.webp",
          large: "/images/2022/Goddess-large.webp",
        },
      },
      {
        title: "Defiance of King Puck",
        description: "Oil on board in artist made frame,",
        dimensions: "67cm x 52.5cm,",
        date: "2022",
        sizes: {
          small: "/images/2022/KingPuck-small.webp",
          medium: "/images/2022/KingPuck-medium.webp",
          large: "/images/2022/KingPuck-large.webp",
        },
      },
      {
        title: "Shavasana Eternal",
        description: "Oil on board in artist made frame,",
        dimensions: "56cm x 47.5cm,",
        date: "2022",
        sizes: {
          small: "/images/2022/Shavasana-small.webp",
          medium: "/images/2022/Shavasana-medium.webp",
          large: "/images/2022/Shavasana-large.webp",
        },
      },
      {
        title: "Hawthorn Trespasser",
        description: "Oil on board in artist made frame,",
        dimensions: "57cm x 52cm,",
        date: "2022",
        sizes: {
          small: "/images/2022/HawthornTrespasser-small.webp",
          medium: "/images/2022/HawthornTrespasser-medium.webp",
          large: "/images/2022/HawthornTrespasser-large.webp",
        },
      },
    ],
    "2021":[
      {
        title: "Finn and I",
        description: "Oil pastel on paper,",
        dimensions: "39.5cm x 26cm,",
        date: "2021",
        sizes: {
          small: "/images/2021/FinnandI-small.webp",
          medium: "/images/2021/FinnandI-medium.webp",
          large: "/images/2021/FinnandI-large.webp",
        },
      },
      {
        title: "Gift to Paddy Joe",
        description: "Oil pastel on paper,",
        dimensions: "25cm x 18cm,",
        date: "2021",
        sizes: {
          small: "/images/2021/GiftToPaddyJoe-small.webp",
          medium: "/images/2021/GiftToPaddyJoe-medium.webp",
          large: "/images/2021/GiftToPaddyJoe-large.webp",
        },
      },
      {
        title: "Warm Mud",
        description: "Oil pastel on paper,",
        dimensions: "39.5cm x 26cm,",
        date: "2021",
        sizes: {
          small: "/images/2021/LluviaCollaboration2-small.webp",
          medium: "/images/2021/LluviaCollaboration2-medium.webp",
          large: "/images/2021/LluviaCollaboration2-large.webp",
        },
      },
      {
        title: "UFO Landing",
        description: "Oil pastel on paper,",
        dimensions: "39.5cm x 26cm,",
        date: "2021",
        sizes: {
          small: "/images/2021/LluviaCollaboration-small.webp",
          medium: "/images/2021/LluviaCollaboration-medium.webp",
          large: "/images/2021/LluviaCollaboration-large.webp",
        },
      },
      {
        title: "Lluvia Reading",
        description: "Oil pastel on paper,",
        dimensions: "22cm x 18cm,",
        date: "2021",
        sizes: {
          small: "/images/2021/LluviaReading-small.webp",
          medium: "/images/2021/LluviaReading-medium.webp",
          large: "/images/2021/LluviaReading-large.webp",
        },
      },
      {
        title: "Listening Frogs",
        description: "Oil pastel on paper,",
        dimensions: "25cm x 20cm,",
        date: "2021",
        sizes: {
          small: "/images/2021/Log-small.webp",
          medium: "/images/2021/Log-medium.webp",
          large: "/images/2021/Log-large.webp",
        },
      },
      {
        title: "RIP Milo",
        description: "Oil pastel on paper,",
        dimensions: "29.5cm x 22cm,",
        date: "2021",
        sizes: {
          small: "/images/2021/MiloRIP-small.webp",
          medium: "/images/2021/MiloRIP-medium.webp",
          large: "/images/2021/MiloRIP-large.webp",
        },
      },
      {
        title: "Red Figure",
        description: "Oil pastel on paper,",
        dimensions: "32m x 26cm,",
        date: "2021",
        sizes: {
          small: "/images/2021/RedFigure-small.webp",
          medium: "/images/2021/RedFigure-medium.webp",
          large: "/images/2021/RedFigure-large.webp",
        },
      },
      {
        title: "Untitled",
        description: "Oil pastel on paper,",
        dimensions: "18cm x 16cm,",
        date: "2021",
        sizes: {
          small: "/images/2021/Untitled4-small.webp",
          medium: "/images/2021/Untitled4-medium.webp",
          large: "/images/2021/Untitled4-large.webp",
        },
      },
      {
        title: "Woodpile",
        description: "Oil pastel on paper,",
        dimensions: "18cm x 24cm,",
        date: "2021",
        sizes: {
          small: "/images/2021/Woodpile-small.webp",
          medium: "/images/2021/Woodpile-medium.webp",
          large: "/images/2021/Woodpile-large.webp",
        },
      },
    ],
      "2020":[
        {
          title: "Jungle Poolside",
          description: "Gouache, watercolour, colour pencil and ink on paper,",
          dimensions: "26cm x 39.5cm,",
          date: "2020",
          sizes: {
            small: "/images/2020/JunglePoolSide-small.webp",
            medium: "/images/2020/JunglePoolSide-medium.webp",
            large: "/images/2020/JunglePoolSide-large.webp",
          },
        },
        {
          title: "Fish in Landscape",
          description: "Gouache on paper,",
          dimensions: "26cm x 39.5cm,",
          date: "2020",
          sizes: {
            small: "/images/2020/FishInLandscape-small.webp",
            medium: "/images/2020/FishInLandscape-medium.webp",
            large: "/images/2020/FishInLandscape-large.webp",
          },
        },
        {
          title: "Headspace",
          description: "Gouache, watercolour, pencil and collage on paper,",
          dimensions: "25cm x 18cm,",
          date: "2020",
          sizes: {
            small: "/images/2020/Headspace-small.webp",
            medium: "/images/2020/Headspace-medium.webp",
            large: "/images/2020/Headspace-large.webp",
          },
        },
        {
          title: "Acceptance",
          description: "Gouache, watercolour, pencil and collage on paper,",
          dimensions: "28cm x 22cm,",
          date: "2020",
          sizes: {
            small: "/images/2020/Acceptance-small.webp",
            medium: "/images/2020/Acceptance-medium.webp",
            large: "/images/2020/Acceptance-large.webp",
          },
        },
        {
          title: "Untitled",
          description: "Graphite on paper,",
          dimensions: "39.5cm x 26cm,",
          date: "2020",
          sizes: {
            small: "/images/2020/Untitled-small.webp",
            medium: "/images/2020/Untitled-medium.webp",
            large: "/images/2020/Untitled-large.webp",
          },
        },
        {
          title: "Imagined Landscape",
          description: "Watercolour and gouache on paper,",
          dimensions: "26cm x 39.5cm,",
          date: "2020",
          sizes: {
            small: "/images/2020/ImaginedLandscape-small.webp",
            medium: "/images/2020/ImaginedLandscape-medium.webp",
            large: "/images/2020/ImaginedLandscape-large.webp",
          },
        },
        {
          title: "Self Portrait",
          description: "Gouache on paper,",
          dimensions: "32cm x 26.5cm,",
          date: "2020",
          sizes: {
            small: "/images/2020/SelfPortrait-small.webp",
            medium: "/images/2020/SelfPortrait-medium.webp",
            large: "/images/2020/SelfPortrait-large.webp",
          },
        },
        {
          title: "Spirit Meeting",
          description: "Watercolour, gouache, pencil and collage on paper,",
          dimensions: "39.5cm x 26cm,",
          date: "2020",
          sizes: {
            small: "/images/2020/SpiritMeeting-small.webp",
            medium: "/images/2020/SpiritMeeting-medium.webp",
            large: "/images/2020/SpiritMeeting-large.webp",
          },
        },
        {
          title: "Imagined Landscape 2",
          description: "Watercolour and gouache and pencil on paper,",
          dimensions: "39.5cm x 26cm,",
          date: "2020",
          sizes: {
            small: "/images/2020/ImaginedLandscape2-small.webp",
            medium: "/images/2020/ImaginedLandscape2-medium.webp",
            large: "/images/2020/ImaginedLandscape2-large.webp",
          },
        },
        {
          title: "Exploring Alternatives",
          description: "Oil pastel on paper,",
          dimensions: "26cm x 39.5cm,",
          date: "2020",
          sizes: {
            small: "/images/2020/ExploringAlternatives-small.webp",
            medium: "/images/2020/ExploringAlternatives-medium.webp",
            large: "/images/2020/ExploringAlternatives-large.webp",
          },
        },
        {
          title: "Female Portrait",
          description: "Graphite on paper,",
          dimensions: "39.5cm x 26cm,",
          date: "2020",
          sizes: {
            small: "/images/2020/Female_portrait-small.webp",
            medium: "/images/2020/Female_portrait-medium.webp",
            large: "/images/2020/Female_portrait-large.webp",
          },
        },
        {
          title: "Angel",
          description: "Acrylic and oil pastel on paper,",
          dimensions: "39.5cm x 26cm,",
          date: "2020",
          sizes: {
            small: "/images/2020/Angel-small.webp",
            medium: "/images/2020/Angel-medium.webp",
            large: "/images/2020/Angel-large.webp",
          },
        },
        {
          title: "Fish Swimming up My Spine",
          description: "Graphite on paper,",
          dimensions: "39.5cm x 26cm,",
          date: "2020",
          sizes: {
            small: "/images/2020/FishSwimminUpMySpine-small.webp",
            medium: "/images/2020/FishSwimminUpMySpine-medium.webp",
            large: "/images/2020/FishSwimminUpMySpine-large.webp",
          },
        },
        {
          title: "Donkey Fight",
          description: "Oil pastel on paper,",
          dimensions: "39.5cm x 26cm,",
          date: "2020",
          sizes: {
            small: "/images/2020/DonkeyFight-small.webp",
            medium: "/images/2020/DonkeyFight-medium.webp",
            large: "/images/2020/DonkeyFight-large.webp",
          },
        },
    ],
    "2019":[
      {
        title: "Pocho's First Lesson",
        description: "Oil, acrylic, oil pastel, roofing mastic and charcoal on canvas,",
        dimensions: "120cm x 160cm,",
        date: "2019",
        sizes: {
          small: "/images/2019/pochoFirstLesson-small.webp",
          medium: "/images/2019/pochoFirstLesson-medium.webp",
          large: "/images/2019/pochoFirstLesson-large.webp",
        },
      },
      {
        title: "Trip with Pocho",
        description: "Oil, acrylic, oil pastel and charcoal on canvas,",
        dimensions: "120cm x 160cm,",
        date: "2019",
        sizes: {
          small: "/images/2019/tripWithPocho-small.webp",
          medium: "/images/2019/tripWithPocho-medium.webp",
          large: "/images/2019/tripWithPocho-large.webp",
        },
      },
      {
        title: "Jungle at Nighttime",
        description: "Oil, acrylic, oil pastel and charcoal on canvas,",
        dimensions: "170cm x 125cm,",
        date: "2019",
        sizes: {
          small: "/images/2019/jungleAtNighttime-small.webp",
          medium: "/images/2019/jungleAtNighttime-medium.webp",
          large: "/images/2019/jungleAtNighttime-large.webp",
        },
      },
      {
        title: "Dismal Day on the Beach",
        description: "Oil, acrylic, oil pastel, roofing mastic and charcoal on canvas,",
        dimensions: "160cm x 240cm,",
        date: "2019",
        sizes: {
          small: "/images/2019/dismalDayOnTheBeach-small.webp",
          medium: "/images/2019/dismalDayOnTheBeach-medium.webp",
          large: "/images/2019/dismalDayOnTheBeach-large.webp",
        },
      },
      {
        title: "Sketch",
        description: "Oil on linen,",
        dimensions: "80cm x 60cm,",
        date: "2019",
        sizes: {
          small: "/images/2019/sketch-small.webp",
          medium: "/images/2019/sketch-medium.webp",
          large: "/images/2019/sketch-large.webp",
        },
      },
      {
        title: "Chillin' in da Wood",
        description: "Oil on board,",
        dimensions: "24cm x 35cm,",
        date: "2019",
        sizes: {
          small: "/images/2019/chillinInDaWood-small.webp",
          medium: "/images/2019/chillinInDaWood-medium.webp",
          large: "/images/2019/chillinInDaWood-large.webp",
        },
      },
      {
        title: "Godlike",
        description: "Oil and oil pastel on mounted canvas on board,",
        dimensions: "30cm x 45cm,",
        date: "2019",
        sizes: {
          small: "/images/2019/godlike-small.webp",
          medium: "/images/2019/godlike-medium.webp",
          large: "/images/2019/godlike-large.webp",
        },
      },
      {
        title: "Indian Boy Breaking Rocks for the Rest of his Life",
        description: "Oil and oil pastel on board,",
        dimensions: "22cm x 22cm,",
        date: "2019",
        sizes: {
          small: "/images/2019/indianBoyBreakingRocksForTheRestOfHisLife-small.webp",
          medium: "/images/2019/indianBoyBreakingRocksForTheRestOfHisLife-medium.webp",
          large: "/images/2019/indianBoyBreakingRocksForTheRestOfHisLife-large.webp",
        },
      },
      {
        title: "Clouds",
        description: "Oil on board,",
        dimensions: "22cm x 18cm,",
        date: "2019",
        sizes: {
          small: "/images/2019/clouds-small.webp",
          medium: "/images/2019/clouds-medium.webp",
          large: "/images/2019/clouds-large.webp",
        },
      },
      {
        title: "Reggae Molecule",
        description: "Oil and oil pastel on board,",
        dimensions: "26cm x 26cm,",
        date: "2019",
        sizes: {
          small: "/images/2019/regaemolecule2-small.webp",
          medium: "/images/2019/regaemolecule2-medium.webp",
          large: "/images/2019/regaemolecule2-large.webp",
        },
      },
      {
        title: "Quadropus",
        description: "Oil and oil pastel on board,",
        dimensions: "40cm x 55cm,",
        date: "2019",
        sizes: {
          small: "/images/2019/quadropuss-small.webp",
          medium: "/images/2019/quadropuss-medium.webp",
          large: "/images/2019/quadropuss-large.webp",
        },
      },
      {
        title: "Man Under Tree",
        description: "Oil board,",
        dimensions: "22cm x 18cm,",
        date: "2019",
        sizes: {
          small: "/images/2019/ManUnderTree-small.webp",
          medium: "/images/2019/ManUnderTree-medium.webp",
          large: "/images/2019/ManUnderTree-large.webp",
        },
      },
      {
        title: "Person on Beach",
        description: "Oil and oil pastel on board,",
        dimensions: "28cm x 18cm,",
        date: "2019",
        sizes: {
          small: "/images/2019/personOnBeach-small.webp",
          medium: "/images/2019/personOnBeach-medium.webp",
          large: "/images/2019/personOnBeach-large.webp",
        },
      },
      {
        title: "Man and Palm Trees Sketch",
        description: "Oil and oil pastel on board,",
        dimensions: "25cm x 18cm,",
        date: "2019",
        sizes: {
          small: "/images/2019/manAndPalmTreesSketch-small.webp",
          medium: "/images/2019/manAndPalmTreesSketch-medium.webp",
          large: "/images/2019/manAndPalmTreesSketch-large.webp",
        },
      },
      {
        title: "Palmdale Sunset",
        description: "Oil on board,",
        dimensions: "25cm x 34cm,",
        date: "2019",
        sizes: {
          small: "/images/2019/palmdaleSunset-small.webp",
          medium: "/images/2019/palmdaleSunset-medium.webp",
          large: "/images/2019/palmdaleSunset-large.webp",
        },
      },
      {
        title: "Hanuman",
        description: "Oil and oil pastel on board,",
        dimensions: "24cm x 18cm,",
        date: "2019",
        sizes: {
          small: "/images/2019/Hanuman-small.webp",
          medium: "/images/2019/Hanuman-medium.webp",
          large: "/images/2019/Hanuman-large.webp",
        },
      },
      {
        title: "Blood",
        description: "Oil on board,",
        dimensions: "16cm x 22cm,",
        date: "2019",
        sizes: {
          small: "/images/2019/blood-small.webp",
          medium: "/images/2019/blood-medium.webp",
          large: "/images/2019/blood-large.webp",
        },
      },
      {
        title: "Day on the Beach",
        description: "Oil, colour pencil and oil pastel on board,",
        dimensions: "16cm x 22cm,",
        date: "2019",
        sizes: {
          small: "/images/2019/dayOnTheBeach-small.webp",
          medium: "/images/2019/dayOnTheBeach-medium.webp",
          large: "/images/2019/dayOnTheBeach-large.webp",
        },
      },
      {
        title: "Indian Boy",
        description: "Oil and oil pastel on board,",
        dimensions: "26cm x 18cm,",
        date: "2019",
        sizes: {
          small: "/images/2019/indianBoy-small.webp",
          medium: "/images/2019/indianBoy-medium.webp",
          large: "/images/2019/indianBoy-large.webp",
        },
      },
      {
        title: "Markings 3",
        description: "Oil on board,",
        dimensions: "26cm x 24cm,",
        date: "2019",
        sizes: {
          small: "/images/2019/markings3-small.webp",
          medium: "/images/2019/markings3-medium.webp",
          large: "/images/2019/markings3-large.webp",
        },
      },
      {
        title: "Painkiller",
        description: "Oil on board,",
        dimensions: "16cm x 12cm,",
        date: "2019",
        sizes: {
          small: "/images/2019/painkiller-small.webp",
          medium: "/images/2019/painkiller-medium.webp",
          large: "/images/2019/painkiller-large.webp",
        },
      },
      {
        title: "Palm Tree Sketch",
        description: "Oil and oil pastel on board,",
        dimensions: "18cm x 14cm,",
        date: "2019",
        sizes: {
          small: "/images/2019/palmTreeSketch-small.webp",
          medium: "/images/2019/palmTreeSketch-medium.webp",
          large: "/images/2019/palmTreeSketch-large.webp",
        },
      },
      {
        title: "Pity",
        description: "Oil, charcoal and oil pastel on board,",
        dimensions: "14cm x 18cm,",
        date: "2019",
        sizes: {
          small: "/images/2019/pity-small.webp",
          medium: "/images/2019/pity-medium.webp",
          large: "/images/2019/pity-large.webp",
        },
      },
      {
        title: "Pocho Eating a Man",
        description: "Oil and oil pastel on board,",
        dimensions: "14cm x 18cm,",
        date: "2019",
        sizes: {
          small: "/images/2019/pochoEatingMan-small.webp",
          medium: "/images/2019/pochoEatingMan-medium.webp",
          large: "/images/2019/pochoEatingMan-large.webp",
        },
      },
    ],
};


export default paintingsData;